:root {
  --header-height: 64px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  font-family: var(--font-open-sans), sans-serif;
}

@media (max-width: 600px) {
  :root {
    --header-height: 56px;
  }
}
